.sticky {
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff; /* Set background color */
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1); /* Add shadow effect with bottom offset */
    transition: all 0.3s ease;
}

.navbar {
    transition: all 0.3s ease;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1); /* Add shadow effect with bottom offset */
    z-index: 1000;
    background-color: #ffffff; /* Set background color */
}

.navbar-nav .nav-item .nav-link {
    font-weight: normal; /* Reset font weight */
    transition: font-weight 0.3s ease; /* Add transition effect */
}

.navbar-nav .nav-item .nav-link:hover {
    font-weight: bold; /* Change font weight on hover */
}

/* #navbarSupportedContent{
    max-width: fit-content;
    border: 1px solid black;
    border-radius: 40px;
    margin: auto;
    padding: 10px;
} */
