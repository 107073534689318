.article-main-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.image {
    width: 100%;
    max-height: 600px;
    object-fit: cover;
    border-radius: 8px;
}

.heading {
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
    text-align: left;
    margin-left: 0px;
    padding-left: 0px;
    color: #000;
}

.text {
    font-size: 18px;
    /* font-weight: 600; */
    word-spacing: 2px;
    text-align: justify;
    color: #000;
    /* line-height: 1.5; */
}

.articleDate{
    font-size: 26px;
    /* font-weight: 600; */
    font-weight: 500;
    margin-top: 20px;
    color: #000;
}


/* For smaller screens */
@media screen and (max-width: 600px) {
    .article-main-container {
        max-width: 100%;
        margin: 5px;
        padding: 20px;
    }
    
    .heading {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
        text-align: left;
        margin-left: 0px;
        padding-left: 0px;
        color: #000;
    }

    .text {
        font-size: 16px;
        word-spacing: 2px;
        text-align: justify;
        color: #000;
    }
}