.quizes-page {
  }
  
  .card {
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow effect to cards */
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 1rem;
  }
  
  .btn-primary {
    font-size: 1rem;
  }
  