.result-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
}

.result-heading {
    text-align: center;
    margin-bottom: 20px;
}

.score-container {
    text-align: center;
    margin-bottom: 30px;
}

.score-text {
    font-size: 2.5rem;
    color: #007bff; /* Example color */
}

.quiz-container {
    margin-top: 20px;
}

.question-container {
    margin-bottom: 15px;
}

.center-container {
    text-align: center;
    margin-top: 20px;
}
