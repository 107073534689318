.container {
  /* max-width: 800px; */
  /* margin: 0 auto; */
  padding: 20px;
}

h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.button-link, .logout-button {
  margin: 5px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: 2px solid black;
  border-radius: 5px;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}

.button-link:hover, .logout-button:hover {
  background-color: #0056b3;
}

.logout-button {
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  .button-container {
    flex-direction: column;
    align-items: center;
  }
}
