/* Hero.css */

.hero-img-container {
    width: 100%; /* Ensures the image container spans the full width */
}

.hero-img-container img {
    width: 100%; /* Makes sure the image fills its container */
    height: auto; /* Maintains the aspect ratio of the image */
}

.hero-content-container {
    display: flex; /* Turns the content container into a flex container */
    flex-direction: column; /* Arranges the content in a column layout */
    justify-content: center; /* Centers the content vertically */
    align-items: center; /* Centers the content horizontally */
}
.hero-content h3{
    font-size: 50px;
}

.hero-content p{
    font-size: 25px;
}

.hero-buttons{
    font-size: 30px;
}

/* Media Query for Mobile Layouts */
@media (max-width: 767.98px) {
    .mainDiv {
        flex-direction: column; /* Sets the container to a column layout for screens smaller than 768px */
        /* align-items: center; */
    }

    .hero-img-container {
        max-width: 100%; /* Allows the image container to take up the full width of its parent */
    }

    .hero-content-container {
        width: 100%; /* Allows the content container to take up the full width of its parent */
    }
     .hero-buttons{
        margin-right: 10px;
        margin-left: 10px;
     }
}

