.centered-form {
    margin: 20px auto; /* Centering the form horizontally with additional margin */
    padding: 20px; /* Adding padding inside the form */
    background-color: #f5f5f5; /* Optional: Adding background color for better visibility */
    border-radius: 10px; /* Optional: Adding border radius for rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Adding box shadow for depth */

    /* Responsive adjustments */
    max-width: 500px; /* Adjust the maximum width of the form */
}

/* Media Query for smaller devices */
@media only screen and (max-width: 600px) {
    .centered-form {
        max-width: 80%; /* Adjust the maximum width for smaller screens */
    }
}
